body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.header-logo {
  height: 10vmin;
}

.header {
  /*background-color: #b7af9d;*/
  background-color: black;
  display: -webkit-flex;
  display: flex;
  /*justify-content: space-between;*/
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top:0vh;
  height: 100px;
  -webkit-justify-content: center;
          justify-content: center;
  z-index:9999;
}

.headerTitle {
    font-family: Oswald;
    font-weight: 700;
    font-size: calc(50px);
    padding-left: 0.75vw;
    padding-top: 1vh;
}

@media (max-width: 450px) {
	.headerTitle {
		font-size: calc(40px);
	}
}


/*@media (max-width: 768px) {*/
  /*.headerTitle {*/
      /*font-size: calc(20px + 2vmin);*/
  /*}*/
  /*.header{*/
      /*height: 50px;*/
  /*}*/
/*}*/



.demopadding {
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.icon {
	position:relative;
	text-align:center;
	width:0px;
	height:0px;
	padding:20px;
	border-top-right-radius: 	20px;
	border-top-left-radius: 	20px;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 	20px;
	-moz-border-radius: 		20px 20px 20px 20px;
	-webkit-border-radius: 		20px 20px 20px 20px;
	-khtml-border-radius: 		20px 20px 20px 20px;
	color:#FFFFFF;
}
.icon i {
	font-size:20px;
	position:absolute;
	left:9px;
	top:10px;
}
.icon.social {
	float:left;
	margin:0 5px 0 0;
	cursor:pointer;
	background: white ;
	color: black;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-filter:	drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-moz-filter: 	drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-ms-filter: 		drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-o-filter: 		drop-shadow(0 1px 2px rgba(0,0,0,.3));
	filter: 			drop-shadow(0 1px 2px rgba(0,0,0,.3));

}

.icon.social a {
	background: white ;
	color: black;
}



.icon.social:hover {
	background:#262626 ;
	color:#ff5252;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-filter:	drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-moz-filter: 	drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-ms-filter: 		drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-o-filter: 		drop-shadow(0 1px 10px rgba(0,0,0,.8));
	filter: 			drop-shadow(0 1px 10px rgba(0,0,0,.8));
}
.icon.social.fb i {
	left:13px;
	top:10px;
}
.icon.social.tw i {
	left:11px;
}
.icon.social.in i {
	left:11px;
}

.icon.social.en i {
	left:10px;
}

.icon.social.gh i {
	left:11px;
}



.nav_bar {
    background-color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: white;
    padding: 0 0 10px 0;
    position: -webkit-sticky;
    position: sticky;
    top:100px;
    font-family: "Oswald";
    z-index:9999;
}

.nav_item {
  padding-left: 1vw;
  padding-right: 1vw;
  font-size: calc(20px);
  font-weight: 700;
  letter-spacing: .05em;
  cursor: pointer;
}

.nav_item.selected{
    color: #ff5252;
}





.content {
  background-color: white;
  min-height: 80vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  /*justify-content: center;*/
  font-size: calc(16px);
  color: black;
  font-family: "Open Sans";
  padding-bottom: 10vh;
}

.content_section {
  padding: 50px 2vw 25px 2vw;
}

.content_title {
  font-size: calc(40px);
  padding: 0 30vw 3vh 30vw;
  /*color: #86A1A9;*/
  font-family: "Oswald";
  font-weight: 700;
  color:#ff5252;
}
.content_paragraph {
  padding: 10px 5vw 10px 5vw;
  font-size:16px;
}

.content a{
  color: #ff5252;
  text-decoration: none;
}

.content a:hover{
  /*text-decoration: underline;*/
}





@media (min-width: 768px) {
  .content_paragraph {
    /*padding: 5vh 0 5vh 0;*/
    width: 740px;
    margin: auto;
  }
}


.content_unit {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top:35px;
}

.content_thumbnail {
    width: 400px;
    height:300px;
    background-position: center;
    background-size: cover;
}

.content_explain {
    width: 400px;
    padding: 20px;
    box-sizing: border-box;
}


@media (max-width: 500px) {
    .content_thumbnail {
        width: 300px;
        height:300px;
    }

    .content_explain {
        width: 300px;
    }

    .content_unit{
        margin-top:80px;
    }
}


.content_explain_title {
    font-family: "Oswald";
    font-size: 25px;
    font-weight: bold;
    color: #ff5252;
    text-align: left;
}

.content_explain_title a:hover{
    /*text-decoration:underline;*/
    color: #ff2222;
}

.content_explain_client_year{
    font-family: "Oswald";
    font-size: 14px;
    font-weight: bold;
    color: #ff5252;
    text-align: left;
}

.content_explain_text{
    font-family: "Open Sans";
    font-size: 16px;
    text-align: left;
    margin-top:20px;
}

.content_explain_credits{
    font-family: "Open Sans";
    font-size: 10px;
    text-align: left;
    margin-top:20px;
    color: #333;
}
.lab_unit {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top:35px;
}

.lab_thumbnail {
    width: 400px;
    height:300px;
    background-position: center;
    background-size: cover;
}

.lab_explain {
    width: 400px;
    padding: 20px;
    box-sizing: border-box;
}


@media (max-width: 500px) {
    .lab_thumbnail {
        width: 300px;
        height:300px;
    }

    .lab_explain {
        width: 300px;
    }

    .lab_unit{
        margin-top:80px;
    }
}

.lab_explain_title {
    font-family: "Oswald";
    font-size: 25px;
    font-weight: bold;
    color: #ff5252;
    text-align: left;
}

.lab_explain_title a:hover{
    /*text-decoration:underline;*/
    color: #ff2222;
}

.lab_explain_client_year{
    font-family: "Oswald";
    font-size: 14px;
    font-weight: bold;
    color: #ff5252;
    text-align: left;
}

.lab_explain_text{
    font-family: "Open Sans";
    font-size: 16px;
    text-align: left;
    margin-top:20px;
}

.lab_explain_credits{
    font-family: "Open Sans";
    font-size: 10px;
    text-align: left;
    margin-top:20px;
    color: #333;
}

.footer {
  background-color: black;
  min-height: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /*position: sticky;*/
  /*bottom: 0vh;*/
}

.footerText{
  font-family: "OpenSans";
  color:#555;
  font-size: 14px;
  margin-top:20px;
}


.iconfooter {
	position:relative;
	text-align:center;
	width:0px;
	height:0px;
	padding:20px;
	border-top-right-radius: 	20px;
	border-top-left-radius: 	20px;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 	20px;
	-moz-border-radius: 		20px 20px 20px 20px;
	-webkit-border-radius: 		20px 20px 20px 20px;
	-khtml-border-radius: 		20px 20px 20px 20px;
	color:#FFFFFF;
}
.iconfooter i {
	font-size:20px;
	position:absolute;
	left:9px;
	top:10px;
}
.iconfooter.social {
	float:left;
	margin:0 5px 0 0;
	cursor:pointer;
	background: #555 ;
	color: black;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-filter:	drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-moz-filter: 	drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-ms-filter: 		drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-o-filter: 		drop-shadow(0 1px 2px rgba(0,0,0,.3));
	filter: 			drop-shadow(0 1px 2px rgba(0,0,0,.3));

}

.iconfooter.social a {
	background: white ;
	color: black;
}



.iconfooter.social:hover {
	background:#262626 ;
	color:#ff5252;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-filter:	drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-moz-filter: 	drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-ms-filter: 		drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-o-filter: 		drop-shadow(0 1px 10px rgba(0,0,0,.8));
	filter: 			drop-shadow(0 1px 10px rgba(0,0,0,.8));
}
.iconfooter.social.fb i {
	left:13px;
	top:10px;
}
.iconfooter.social.tw i {
	left:11px;
}
.iconfooter.social.in i {
	left:11px;
}

.iconfooter.social.en i {
	left:10px;
}

.iconfooter.social.gh i {
	left:11px;
}

.App {
  text-align: center;
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
}

@font-face {
    font-family: "Oswald";
    src: url(../../static/media/Oswald-ExtraLight.d9b2475a.woff2) format('woff2'),
         url(../../static/media/Oswald-ExtraLight.b889dd22.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}


@font-face {
    font-family: "Oswald";
    src: url(../../static/media/Oswald-Light.79de9ef8.woff2) format('woff2'),
         url(../../static/media/Oswald-Light.d10552fd.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: "Oswald";
    src: url(../../static/media/Oswald-Regular.66686f86.woff2) format('woff2'),
         url(../../static/media/Oswald-Regular.68a85f5c.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}



@font-face {
    font-family: "Oswald";
    src: url(../../static/media/Oswald-Medium.6afbfedb.woff2) format('woff2'),
         url(../../static/media/Oswald-Medium.3e97dbdb.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}



@font-face {
    font-family: "Oswald";
    src: url(../../static/media/Oswald-SemiBold.20af52a8.woff2) format('woff2'),
         url(../../static/media/Oswald-SemiBold.fa8b5c8c.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: "Oswald";
    src: url(../../static/media/Oswald-Bold.1aa597df.woff2) format('woff2'),
         url(../../static/media/Oswald-Bold.ad9c6c18.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}



/*TOURMALINE*/

/*HEX/HTML 86A1A9*/

/*CLOUD DANCER*/

/*HEX/HTML F0EEE9*/

/*GRAY VIOLET*/

/*HEX/HTML BBBCBC*/

/*PURITAN GRAY*/

/*HEX/HTML A8B0AE*/


