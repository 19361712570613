
.footer {
  background-color: black;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /*position: sticky;*/
  /*bottom: 0vh;*/
}

.footerText{
  font-family: "OpenSans";
  color:#555;
  font-size: 14px;
  margin-top:20px;
}


.iconfooter {
	position:relative;
	text-align:center;
	width:0px;
	height:0px;
	padding:20px;
	border-top-right-radius: 	20px;
	border-top-left-radius: 	20px;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 	20px;
	-moz-border-radius: 		20px 20px 20px 20px;
	-webkit-border-radius: 		20px 20px 20px 20px;
	-khtml-border-radius: 		20px 20px 20px 20px;
	color:#FFFFFF;
}
.iconfooter i {
	font-size:20px;
	position:absolute;
	left:9px;
	top:10px;
}
.iconfooter.social {
	float:left;
	margin:0 5px 0 0;
	cursor:pointer;
	background: #555 ;
	color: black;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-filter:	drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-moz-filter: 	drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-ms-filter: 		drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-o-filter: 		drop-shadow(0 1px 2px rgba(0,0,0,.3));
	filter: 			drop-shadow(0 1px 2px rgba(0,0,0,.3));

}

.iconfooter.social a {
	background: white ;
	color: black;
}



.iconfooter.social:hover {
	background:#262626 ;
	color:#ff5252;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-filter:	drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-moz-filter: 	drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-ms-filter: 		drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-o-filter: 		drop-shadow(0 1px 10px rgba(0,0,0,.8));
	filter: 			drop-shadow(0 1px 10px rgba(0,0,0,.8));
}
.iconfooter.social.fb i {
	left:13px;
	top:10px;
}
.iconfooter.social.tw i {
	left:11px;
}
.iconfooter.social.in i {
	left:11px;
}

.iconfooter.social.en i {
	left:10px;
}

.iconfooter.social.gh i {
	left:11px;
}
