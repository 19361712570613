.lab_unit {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin-top:35px;
}

.lab_thumbnail {
    width: 400px;
    height:300px;
    background-position: center;
    background-size: cover;
}

.lab_explain {
    width: 400px;
    padding: 20px;
    box-sizing: border-box;
}


@media (max-width: 500px) {
    .lab_thumbnail {
        width: 300px;
        height:300px;
    }

    .lab_explain {
        width: 300px;
    }

    .lab_unit{
        margin-top:80px;
    }
}

.lab_explain_title {
    font-family: "Oswald";
    font-size: 25px;
    font-weight: bold;
    color: #ff5252;
    text-align: left;
}

.lab_explain_title a:hover{
    /*text-decoration:underline;*/
    color: #ff2222;
}

.lab_explain_client_year{
    font-family: "Oswald";
    font-size: 14px;
    font-weight: bold;
    color: #ff5252;
    text-align: left;
}

.lab_explain_text{
    font-family: "Open Sans";
    font-size: 16px;
    text-align: left;
    margin-top:20px;
}

.lab_explain_credits{
    font-family: "Open Sans";
    font-size: 10px;
    text-align: left;
    margin-top:20px;
    color: #333;
}