
.nav_bar {
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0 0 10px 0;
    position: sticky;
    top:100px;
    font-family: "Oswald";
    z-index:9999;
}

.nav_item {
  padding-left: 1vw;
  padding-right: 1vw;
  font-size: calc(20px);
  font-weight: 700;
  letter-spacing: .05em;
  cursor: pointer;
}

.nav_item.selected{
    color: #ff5252;
}



