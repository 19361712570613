
.content {
  background-color: white;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  font-size: calc(16px);
  color: black;
  font-family: "Open Sans";
  padding-bottom: 10vh;
}

.content_section {
  padding: 50px 2vw 25px 2vw;
}

.content_title {
  font-size: calc(40px);
  padding: 0 30vw 3vh 30vw;
  /*color: #86A1A9;*/
  font-family: "Oswald";
  font-weight: 700;
  color:#ff5252;
}
.content_paragraph {
  padding: 10px 5vw 10px 5vw;
  font-size:16px;
}

.content a{
  color: #ff5252;
  text-decoration: none;
}

.content a:hover{
  /*text-decoration: underline;*/
}





@media (min-width: 768px) {
  .content_paragraph {
    /*padding: 5vh 0 5vh 0;*/
    width: 740px;
    margin: auto;
  }
}

