
.header-logo {
  height: 10vmin;
}

.header {
  /*background-color: #b7af9d;*/
  background-color: black;
  display: flex;
  /*justify-content: space-between;*/
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  top:0vh;
  height: 100px;
  justify-content: center;
  z-index:9999;
}

.headerTitle {
    font-family: Oswald;
    font-weight: 700;
    font-size: calc(50px);
    padding-left: 0.75vw;
    padding-top: 1vh;
}

@media (max-width: 450px) {
	.headerTitle {
		font-size: calc(40px);
	}
}


/*@media (max-width: 768px) {*/
  /*.headerTitle {*/
      /*font-size: calc(20px + 2vmin);*/
  /*}*/
  /*.header{*/
      /*height: 50px;*/
  /*}*/
/*}*/



.demopadding {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.icon {
	position:relative;
	text-align:center;
	width:0px;
	height:0px;
	padding:20px;
	border-top-right-radius: 	20px;
	border-top-left-radius: 	20px;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 	20px;
	-moz-border-radius: 		20px 20px 20px 20px;
	-webkit-border-radius: 		20px 20px 20px 20px;
	-khtml-border-radius: 		20px 20px 20px 20px;
	color:#FFFFFF;
}
.icon i {
	font-size:20px;
	position:absolute;
	left:9px;
	top:10px;
}
.icon.social {
	float:left;
	margin:0 5px 0 0;
	cursor:pointer;
	background: white ;
	color: black;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-filter:	drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-moz-filter: 	drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-ms-filter: 		drop-shadow(0 1px 2px rgba(0,0,0,.3));
	-o-filter: 		drop-shadow(0 1px 2px rgba(0,0,0,.3));
	filter: 			drop-shadow(0 1px 2px rgba(0,0,0,.3));

}

.icon.social a {
	background: white ;
	color: black;
}



.icon.social:hover {
	background:#262626 ;
	color:#ff5252;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-filter:	drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-moz-filter: 	drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-ms-filter: 		drop-shadow(0 1px 10px rgba(0,0,0,.8));
	-o-filter: 		drop-shadow(0 1px 10px rgba(0,0,0,.8));
	filter: 			drop-shadow(0 1px 10px rgba(0,0,0,.8));
}
.icon.social.fb i {
	left:13px;
	top:10px;
}
.icon.social.tw i {
	left:11px;
}
.icon.social.in i {
	left:11px;
}

.icon.social.en i {
	left:10px;
}

.icon.social.gh i {
	left:11px;
}

