.App {
  text-align: center;
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
}

@font-face {
    font-family: "Oswald";
    src: url("fonts/Oswald-ExtraLight.woff2") format('woff2'),
         url("fonts/Oswald-ExtraLight.ttf") format('truetype');
    font-weight: 200;
    font-style: normal;
}


@font-face {
    font-family: "Oswald";
    src: url("fonts/Oswald-Light.woff2") format('woff2'),
         url("fonts/Oswald-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: "Oswald";
    src: url("fonts/Oswald-Regular.woff2") format('woff2'),
         url("fonts/Oswald-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}



@font-face {
    font-family: "Oswald";
    src: url("fonts/Oswald-Medium.woff2") format('woff2'),
         url("fonts/Oswald-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}



@font-face {
    font-family: "Oswald";
    src: url("fonts/Oswald-SemiBold.woff2") format('woff2'),
         url("fonts/Oswald-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: "Oswald";
    src: url("fonts/Oswald-Bold.woff2") format('woff2'),
         url("fonts/Oswald-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}



/*TOURMALINE*/

/*HEX/HTML 86A1A9*/

/*CLOUD DANCER*/

/*HEX/HTML F0EEE9*/

/*GRAY VIOLET*/

/*HEX/HTML BBBCBC*/

/*PURITAN GRAY*/

/*HEX/HTML A8B0AE*/

